.hide_print{
  color: transparent;
  }
  .part1 {
      margin-top: 1px 
  }
  .flex {
  display: flex;
  }
  .flex-part2 {
  display: flex;
  width: 20.5cm;
  flex: 1.9cm 8.4cm 1.9cm 8.4cm;
  }
  .part1>.flex>div>p {
  margin-left: 0.1cm;
  margin-top: 0.2cm;
  width: 1.2cm;
  }
  .flex{
  display: flex;
  }
  .flex_part1_sub{
      display:flex;
      width: 4.1cm;
  }
  .part1-label{
  width: 1cm;
  }
  .part1-value {
  width: 4.8cm;
  }
  .flex-column {
  display: flex;
  flex-direction: column;
  }
  .part2 {
  height: 2.4cm;
  }
  .part2_label {
  width: 2cm;
  }
  .part2_value {
  width: 8.7cm;
  }
  .part2_sub_value{
  width: 4.3cm;
  }
  .part3_label {
  width: 2cm;
  margin: 0;
  height: 0.7cm;
  }
  .part3_value {
  width: 10.8cm;
  margin: 0;
  height: 0.6cm;
  }
  .part3_label_blank {
  width: 2cm;
  height: 1.2cm;
  margin-top: -0.6cm;
  }
  
  .part3_value_blank {
  width: 10.8cm;
  height: 1.2cm;
  margin-top: -0.6cm;
  }
  
  .part4_value_blank {
  width: 12.8cm;
  margin-top: 0cm;
  margin-left: 0cm;
  height: 1.8cm;
  }
  
  .part1_sub_label {
  width:1.6cm
  }
  .part1_sub_value{
  width: 2.9cm;
  }
  .part2 {
      margin-top: 3px;
  }
  .part2 p {
  margin: 0
  }
  
  #part2 {
  display: flex;
  height: 0.6cm;
  }
  
  .flex_part3 {
  display: flex;
  margin-left: 0.1cm;
  }
  .padding_packages {
      padding-top: 0.2cm
  }
  .gap_part3 {
  margin-top: 0.1cm
  }
  
  #part2>p {
  height: 0.6cm;
  margin: 0
  }
  
  div.part3_label>p {
  margin: 0;
  }
  #asd{
  width:20.5cm;
  }